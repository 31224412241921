import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Button, Divider, List, Input, Modal } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TagContext } from "../../../library/hooks/context/TagContext";
import { tabs } from "../../../library/constants/nav-tabs";
import EditTag from "../../views/KeywordTagManager/EditTag";
import Wizard from "../../views/GetStarted/Wizard";
export default function TagsOverlay({ setVisible, forwardRef, dropdownVisible }) {
  const tagContext = useContext(TagContext);
  const dropdownRef = useRef();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [wizardKey, setWizardKey] = useState(Math.random());
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [tagDataSource, setTagDataSource] = useState([]);

  const showModal = () => {
    if (!tagContext.modalopen) {
      setWizardKey(Math.random());
      setVisible(false);
      setIsModalOpen(true);
      tagContext.setModalopen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    tagContext.setModalopen(false);
    const appRankingTab = tabs
      .flat()
      .find((tab) => tab.value === "keyword_rankings");
    return navigate(
      `/workspace/${tagContext.selectedTag?.slug}/app/${tagContext.selectedApp.appId}/keywords`,
      {
        state: {
          pageFunction: appRankingTab?.function,
          pageName: appRankingTab?.value,
        },
      }
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    tagContext.setModalopen(false);
  };

  const handleTagSelect = (tag) => {
    tagContext.setSelectedTag(tag);
    setVisible(false);
  };

  const handleEditTag = (tag) => {
    handleTagSelect(tag);
    if (!tagContext.userData.groups?.includes("cma")) {
      setModalOpenEdit(true);
    } else {
      const link = `/workspace/${tag.slug}/edit`;
      navigate(link);
      setVisible(false);
    }
  };

  const getListItemStyle = (item) => {
    const itemStyle = {
      padding: "12px",
    };
    if (item.slug === tagContext.selectedTag.slug) {
      itemStyle.backgroundColor = "#ffeb96dd";
    } else {
      itemStyle.backgroundColor = "#fff";
    }
    return itemStyle;
  };

  useEffect(
    () => {
      setShowAddButton(false);
      return () => {
        setShowAddButton(false);
      };
    },
    [] // Only call effect if debounced search term changes
  );

  useEffect(
    () => {
      setTagDataSource(tagContext.tagList);
    },
    [tagContext.tagList] // Only call effect if debounced search term changes
  );

  useEffect(
    () => {
      if (!tagContext.tagList?.length) {
        if (searchTerm.length) {
          setShowAddButton(true);
        } else {
          setShowAddButton(false);
        }

        return;
      }
      const fList = tagContext.tagList.filter((item) =>
        item.Name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTagDataSource(fList);

      const filteredItems = tagContext.tagList.filter(
        (item) => item.Name.toLowerCase() === searchTerm.toLowerCase()
      );
      if (
        (!filteredItems.length && searchTerm.length) ||
        (fList.length == 0 && searchTerm.length)
      ) {
        setShowAddButton(true);
      } else {
        setShowAddButton(false);
      }
    },
    [searchTerm] // Only call effect if debounced search term changes
  );


  useEffect(() => {
    console.log("dropdownVisible", dropdownVisible);
    if (dropdownVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [dropdownVisible]);

  //<AddTag />
  return (
    <>
      <Modal
        open={modalOpenEdit}
        title="Edit"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setModalOpenEdit(false)}
      >
        <EditTag />
      </Modal>

      {tagContext.tagList && (
        <div ref={dropdownRef} className="nav-tabs__dropdown-overlay">
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              padding: 2,
              alignItems: "center",
            }}
          >
            <Input
              ref={inputRef}
              className="search-bar"
              placeholder={
                tagContext.tagList?.length
                  ? "Search or create Workspace"
                  : "Type in Workspace name to create"
              }
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ flex: "1" }}
              allowClear
            />
            {showAddButton && (
              <>
                <Button
                  onClick={showModal}
                  type="primary"
                  style={{
                    whiteSpace: "nowrap",
                    flex: "0",
                    marginLeft: 5,
                  }}
                  className="add-button"
                >
                  Create
                </Button>

                <Wizard
                  key={wizardKey}
                  isModalOpen={isModalOpen}
                  showpage={1}
                  workspacetext={searchTerm}
                  handleOk={handleOk}
                  handleCancel={handleCancel}
                />
              </>
            )}
          </div>
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <List
            style={{ overflow: "auto", overflowX: "hidden" }}
            dataSource={tagDataSource}
            className="tag-dropdown"
            renderItem={(item) => (
              <List.Item
                className="tag-dropdown__item"
                key={item.slug}
                style={getListItemStyle(item)}
              >
                <List.Item.Meta
                  title={item?.Name}
                  onClick={() => handleTagSelect(item)}
                />

                <Tooltip title="Edit Workspace">
                  <Button
                    className="edit-button"
                    shape="circle"
                    size="small"
                    type="text"
                    onClick={() => handleEditTag(item)}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              </List.Item>
            )}
          />
        </div>
      )}
    </>
  );
}
