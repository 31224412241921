import { lazy } from "react";

const GetStarted = lazy(() => import("../components/views/GetStarted/GetStarted"));

const KeywordTagManager = lazy(() => import("../components/views/KeywordTagManager/KeywordTagManager"));

const AppInfo = lazy(() => import("../components/views/AppInfo/AppInfo"));

const AppRankings = lazy(() => import("../components/views/AppRankings/AppRankings"));

const Keywords = lazy(() => import("../components/views/Keywords/Keywords"));
const Dashboard = lazy(() => import("../components/views/Dashboard/Dashboard"));

const Reviews = lazy(() => import("../components/views/Reviews/Reviews"));

const WorldCharts = lazy(() => import("../components/views/WorldCharts/WorldCharts"));
const AccountPage = lazy(() => import("../components/views/Account/AccountPage"));
const AdminPage = lazy(() => import("../components/views/Admin/AdminPage"));
//const Welcome = lazy(() => import('../components/views/Welcome/Welcome'));

const SignupConfirm = lazy(() => import("../components/views/Account/Registration/SignupConfirm"));
const SignUpVerify = lazy(() => import("../components/views/Account/Registration/SignUpVerify"));
const Pricing = lazy(() => import("../components/views/Account/Pricing/Pricing"));
const Tutorials = lazy(() => import("../components/views/Account/Tutorials/Videos"));
const ForgotPassword = lazy(() => import("../components/views/Account/Registration/ForgotPassword"));
const ResetPassword = lazy(() => import("../components/views/Account/Registration/ResetPassword"));
const SignUp = lazy(() => import("../components/views/Account/Registration/SignUp"));
const Login = lazy(() => import("../components/views/Account/Registration/Login"));

//const CompetitorAnalyzer = lazy(() => import('../components/views/Competitor/CompetitorAnalyzer'));

const SlackVerify = lazy(() => import("../components/views/Authentication/SlackVerify"));
//import CmaLabs from '../components/views/Labs/CmaLabs';
const Notifications = lazy(() => import("../components/views/Notifications/Notifications"));

const CmaLabs = lazy(() => import("../components/views/Labs/CmaLabs"));
const AppleSearchAds = lazy(() => import("../components/views/AppleSearchAds/AppleSearchAds"));
const Connectors = lazy(() => import("../components/views/Connectors/Connectors"));

const ASAVerify = lazy(() => import("../components/views/AppleSearchAds/ASAVerify"));

export const subTagLinkPrefix = "workspace/:tag_slug";
export const subAppLinkPrefix = "/app/:app_id";

export const routes = [
  {
    label: "Get Started",
    name: "get_started",
    link: "/",
    includeNav: true,
    component: GetStarted,
  },
  {
    label: "Workspace Manager",
    name: "workspace_manager",
    link: "/edit",
    includeNav: true,
    sub_tag_link: true,
    component: KeywordTagManager,
  },
  {
    label: "Notifications",
    name: "notifications",
    link: "/notifications",
    includeNav: true,
    sub_tag_link: false,
    component: Notifications,
  },
  {
    label: "App Info",
    name: "app_info",
    link: "/appinfo",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: true,
    component: AppInfo,
  },
  {
    label: "App Download Rankings",
    name: "app_rankings",
    link: "/apprankings",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: true,
    component: AppRankings,
  },

  {
    label: "Keywords",
    name: "keyword_rankings",
    link: "/keywords",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: true,
    component: Keywords,
  },
  { label: "Dashboard", name: "dashboard", link: "/dashboard", includeNav: true, component: Dashboard, sub_tag_link: true },
  {
    label: "App Reviews",
    name: "app_reviews",
    link: "/reviews",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: true,
    component: Reviews,
  },
  {
    label: "CMA Labs",
    name: "cma_labs",
    link: "/cmalabs",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: true,
    component: CmaLabs,
  },
  {
    label: "Apple Search Ads",
    name: "apple_search_ads",
    link: "/applesearchads",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: false,
    component: AppleSearchAds,
  },
  {
    label: "Connectors",
    name: "connectors",
    link: "/connectors",
    includeNav: true,
    sub_tag_link: true,
    sub_app_link: false,
    component: Connectors,
  },
  {
    label: "World Charts",
    name: "world_charts",
    link: "/worldcharts",
    includeNav: true,
    component: WorldCharts,
  },
  {
    label: "Account",
    name: "account",
    link: "/account",
    includeNav: true,
    component: AccountPage,
  },
  {
    label: "Admin",
    name: "admin",
    link: "/admin",
    includeNav: true,
    component: AdminPage,
  },
  {
    label: "Registration",
    name: "registration",
    link: "/registration",
    includeNav: true,
    component: SignUp,
  },
  {
    label: "Login",
    name: "login",
    link: "/login",
    includeNav: true,
    component: Login,
  },
  {
    label: "Forgot Password",
    name: "forgot_password",
    link: "/forgotpassword",
    includeNav: true,
    component: ForgotPassword,
  },
  {
    label: "Sign Up Confirmation",
    name: "signup_confirm",
    link: "/signupconfirm",
    includeNav: false,
    component: SignupConfirm,
  },
  {
    label: "Sign Up Verification",
    name: "verify",
    link: "/verify",
    includeNav: false,
    component: SignUpVerify,
  },
  {
    label: "Apple Search Ads Verification",
    name: "asaverify",
    link: "/asaverify",
    includeNav: false,
    component: ASAVerify,
  },
  {
    label: "Slack Verification",
    name: "slackverify",
    link: "/slackverify",
    includeNav: false,
    component: SlackVerify,
  },
  {
    label: "Reset Password",
    name: "reset_password",
    link: "/resetpassword",
    includeNav: false,
    component: ResetPassword,
  },
  {
    label: "Pricing",
    name: "pricing",
    link: "/pricing",
    includeNav: true,
    component: Pricing,
  },

  {
    label: "Tutorials",
    name: "tutorials",
    link: "/tutorials",
    includeNav: true,
    component: Tutorials,
  },
];
